import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BsGraphUp } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import {
  PiMonitorPlayThin,
  PiFilesThin,
  PiSpeedometerLight,
  PiClockClockwiseLight,
  PiPresentationChartLight,
  PiFilePdfLight,
  PiExportLight,
} from 'react-icons/pi';
import { IoSettingsOutline } from 'react-icons/io5';
import useDarkMode from 'hooks/useDarkMode';
import _ from 'lodash';

const DrawerMenu = ({ navHeight }) => {
  const [isDarkMode] = useDarkMode();
  const [visible, setVisible] = useState(false);
  const { user } = useSelector((state) => state.general);
  const location = useLocation(); // To get the current URL
  const generalView = useSelector((state) => state.settings.views);
  const { drillerViews } = useSelector((state) => state.settings);
  const isUpdatingDrillerMenu = location.pathname.includes('driller-menu');
  const [path, setPath] = useState(location.pathname);

  // Rerender component on path change
  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  // Show the drawer when mouse enters, hide it when mouse leaves
  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);
  const checkRoles = (roles) => _.includes(roles, user?.role);

  // eslint-disable-next-line no-nested-ternary
  const listOfViews = location.pathname.includes('create-view')
    ? [...generalView, ...drillerViews]
    : !location.pathname.includes('driller-menu')
    ? generalView
    : drillerViews;

  const selectedViewId =
    _.find(listOfViews, { name: isUpdatingDrillerMenu ? user.selected_view_driller : user.selected_view })?.id ||
    _.find(listOfViews, {
      name: isUpdatingDrillerMenu ? user?.usersetting?.selected_view_driller : user?.usersetting?.selected_view,
    })?.id;

  // Menu items
  const items = [
    {
      key: '1',
      icon: <BsGraphUp size={24} />,
      label: 'Graphs',
      path: '/home', // URL path to match
    },
    {
      key: '2',
      icon: <PiClockClockwiseLight size={24} />,
      label: 'Historical',
      path: '/historical',
    },
    {
      key: '3',
      icon: <PiSpeedometerLight size={24} />,
      label: 'Driller Panel',
      path: '/driller-menu',
    },
    {
      key: '4',
      icon: <PiPresentationChartLight size={24} />,
      label: 'Mud System',
      path: '/tank-menu',
    },
    {
      key: '5',
      icon: <PiMonitorPlayThin size={24} />,
      label: 'System',
      path: '/monitoring',
    },
    {
      key: '6',
      icon: <PiExportLight size={24} />,
      label: 'Export Raw Data',
      path: '/export',
    },
    {
      key: '7',
      icon: <PiFilePdfLight size={24} />,
      label: 'Export Reports ',
      path: selectedViewId ? `/report-page?viewId=${selectedViewId}` : '/report-page',
    },
  ];

  // Bottom menu items
  const menubottom = [
    checkRoles(['superadmin', 'admin']) && {
      key: '8',
      icon: <PiFilesThin size={24} />,
      label: <Link to="/logs">Logs</Link>,
      path: '/logs',
    },
    {
      key: '9',
      icon: <IoSettingsOutline size={24} />,
      label: <Link to="/settings">Settings</Link>,
      path: '/settings',
    },
    {
      key: '10',
      icon: <div className="w-full text-xs text-center mx-auto -ml-2 -mb-3">v2.7.2</div>,
      label: <div />,
      path: '',
    },
  ];

  return (
    <div
      className={`sidebar-menu-container ${
        visible ? '' : 'overflow-hidden'
      } w-[50px] max-w-[50px] rounded-md dark:text-white dark:border-lightDark 
      ${location.pathname.includes('driller-menu') && 'max-h-[900px]'}
      `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        // height: path?.includes('driller-menu') ? 'calc(100vh - 20px)' : '100%',
        height: path?.includes('driller-menu') ? '100vh' : `calc(100vh - ${navHeight}px)`,
      }}
    >
      <div
        style={{
          minHeight: path?.includes('report-page') ? '650px' : '100%',
        }}
        className={`sidebar h-full  ${
          visible ? 'w-[240px]' : 'w-[50px]'
        } transition-all duration-200 rounded border border-lighterDark/30 ${isDarkMode ? 'bg-dark' : 'bg-white'}`}
      >
        <div className="flex flex-1 flex-col justify-between h-full">
          {/* Top Menu */}
          <ul className="flex flex-col px-1 space-y-4 pt-5">
            {items.map((item) => (
              <li
                key={item.key}
                className={`
                  p-2 cursor-pointer rounded min-w-fit flex items-start justify-start hover:bg-lighterDark/30 relative text-sm ${
                    location.pathname === item.path ? 'bg-primary/30 dark:bg-primary/30' : ''
                  }`}
              >
                <Link className="w-full" to={item?.path}>
                  <div className={`${visible ? 'pr-2' : 'pr-0'}`}>{item.icon}</div>
                  <span
                    className={`transform  w-fit ${
                      visible ? 'translate-x-0 opacity-100 delay-200 duration-400' : '-translate-x-10 opacity-0'
                    } transition-all absolute left-10 top-2`}
                  >
                    {item.label}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
          {/* Bottom Menu */}
          <ul className="flex flex-col px-1 space-y-2 pb-3 ">
            {menubottom.map(
              (item) =>
                item && (
                  <li
                    key={item.key}
                    className={`
                        p-2
                        rounded
                        flex
                        items-start
                        justify-start
                        ${item.key !== '10' && 'hover:bg-lighterDark/30 cursor-pointer'}
                        relative
                        text-sm ${location.pathname === item.path ? 'bg-primary/30 dark:bg-primary/30' : ''}`}
                  >
                    <Link className="w-full" to={item?.path}>
                      <div className={`${visible ? 'pr-2' : 'pr-0'}`}>{item.icon}</div>
                      <span
                        className={`transform  ${
                          visible ? 'translate-x-0 opacity-100 delay-200 duration-400' : '-translate-x-10 opacity-0'
                        } transition-all absolute left-10 top-2 w-fit`}
                      >
                        {item.label}
                      </span>
                    </Link>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DrawerMenu;
