import * as types from '../types';

const initialState = {
  loading: false,
  exportedFilesList: [],
  exportingFiles: [],
};

export default function reportReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.TOGGLE_REPORT_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case types.GET_EXPORTED_FILES:
      return {
        ...state,
        exportedFilesList: payload,
      };
    case types.SET_EXPORTING_FILE_STATUS:
      return {
        ...state,
        exportingFiles: payload,
      };
    default:
      return state;
  }
}
