import { useEffect, useRef, useState } from 'react';
import { httpService } from 'api/api';
import ApiToastHandle from 'containers/ApiToastHandle';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  getStoredStands,
  getStringTypes,
  getToolsInserted,
  getToolsInsertedAll,
  getToolsInsertedWithStand,
  insertedStandsLength,
  setToolsInsertedCopy,
  toggleFirstActiveInsertedTools,
  toggleLastInactiveInsertedTools,
} from 'store/actions/addTools';
import { getDrillerLogs } from 'store/actions/drillerMenu';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import { getAutoDepth, getSafetyToolsStatus } from 'store/actions/general';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { getTallyStandNumber, getTallyStandStatus } from 'store/actions/liveData';
import { setLoading } from 'store/actions/settings';

const F2_KEY = 113;
const F3_KEY = 114;
const F8_KEY = 119;
const F9_KEY = 120;
const DEBOUNCE_DELAY = 1000; // 3 seconds debounce delay

const PipeController = () => {
  const dispatch = useDispatch();
  const { toolInsertedAll, stringTypes, toolInserted, toolsInsertedCopy } = useSelector((state) => state.addTools);
  const { safetyToolsStatus, loading } = useSelector((state) => state.settings);
  const realTimeData = useSelector((state) => state.liveData.data);
  const { auto_tally_stand_number, auto_tally_stand_status } = useSelector((state) => state.liveData);
  const [data, setData] = useState(toolInsertedAll);
  const [openPipeModal, setOpenPipeModal] = useState(false);
  const [modalTxt, setModalTxt] = useState('adding');
  const autoTally = realTimeData?.boolAutoBHA ? JSON.parse(realTimeData?.boolAutoBHA) : false;
  const { autoDepthstatus } = useSelector((state) => state.general);
  const [debouncedInsertedTools, setDebouncedInsertedTools] = useState(toolsInsertedCopy);
  const [lastKeyPress, setLastKeyPress] = useState(null);
  const [isDebounceActive, setDebounceActive] = useState(false);

  const confirmButtonRef = useRef(null);

  useEffect(() => {
    dispatch(getAutoDepth('auto_depth'));
    dispatch(getSafetyToolsStatus());
    dispatch(getStringTypes());
    dispatch(getToolsInsertedAll('store_stand=all'));
    dispatch(getToolsInsertedWithStand('store_stand=stand'));
    dispatch(getDrillerLogs());
    dispatch(getToolsInserted());
    dispatch(getTallyStandNumber('auto_tally_stand_number'));
    dispatch(getTallyStandStatus('auto_tally_stand_status'));
  }, []);

  useEffect(() => {
    setData(toolInsertedAll);
    const drillPipeTypeId = _.find(stringTypes, { name: 'Drill pipe' })?.id;
    if (drillPipeTypeId) {
      const activeDrillPipes = _.filter(
        toolInsertedAll,
        (tool) => tool.active === true && tool?.string_type === drillPipeTypeId
      );
      const totalLength = activeDrillPipes?.length;
      const computedResult = Number(totalLength / auto_tally_stand_number).toFixed(2);
      dispatch(insertedStandsLength(computedResult));
    }
  }, [auto_tally_stand_number, toolInsertedAll]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedInsertedTools(toolsInsertedCopy);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [toolsInsertedCopy]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { keyCode } = event;
      const isF2Key = keyCode === F2_KEY;
      const isF3Key = keyCode === F3_KEY;
      const isF8Key = keyCode === F8_KEY;
      const isF9Key = keyCode === F9_KEY;

      if ((isF2Key && !loading) || (isF3Key && !loading)) {
        event.preventDefault();
        event.stopPropagation();

        setLastKeyPress(Date.now()); // Update the last key press time

        if (safetyToolsStatus) {
          setModalTxt(isF2Key ? 'adding' : 'removing');
          setOpenPipeModal(true);
        } else if (isF2Key) {
          dispatch(toggleLastInactiveInsertedTools());
        } else {
          dispatch(toggleFirstActiveInsertedTools());
        }

        setDebounceActive(true);
      }

      if (isF8Key && !loading) {
        updateStandPipe({ stand_number: Number(auto_tally_stand_number), stand_event: true });
      }
      if (isF9Key && !loading) {
        updateStandPipe({ stand_number: Number(auto_tally_stand_number), stand_event: false });
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [data, autoTally, autoDepthstatus?.auto_depth, safetyToolsStatus, loading]);

  const keyRef = useRef(null); // Use ref to persist the key value across renders

  useEffect(() => {
    const handleKeyDown = (event) => {
      keyRef.current = event.keyCode; // Store the last pressed key code
      setLastKeyPress(Date.now()); // Update the last key press timestamp
      setDebounceActive(true); // Activate debounce when a key is pressed
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Only set up the event listener once

  useEffect(() => {
    if (isDebounceActive && !loading && (keyRef.current === 113 || keyRef.current === 114)) {
      const timeout = setTimeout(() => {
        if (Date.now() - lastKeyPress >= DEBOUNCE_DELAY && debouncedInsertedTools.length > 0) {
          const lastActiveItem = debouncedInsertedTools.find((item) => item.active === true);
          const secondLastActiveItemIndex = debouncedInsertedTools.findIndex((item) => item.active === true) - 1;
          const secondLastActiveItem = [...debouncedInsertedTools]?.[secondLastActiveItemIndex];
          const dbActive = toolInserted.findIndex((item) => item.active === true);
          // if (keyRef.current === 114 && toolInserted.at(-1).active === false) {
          //   ApiToastHandle(410, 'All the strings are inactive', 'info');
          // } else if (keyRef.current === 113 && toolInserted?.[0].active === true) {
          //   ApiToastHandle(410, 'All the strings are active', 'info');
          // } else

          if (toolInserted.every((item) => item.active === false)) {
            dispatch(setLoading(true));
            updateToolsAsBulk(lastActiveItem?.id);
          } else if (
            lastActiveItem &&
            dbActive < debouncedInsertedTools.findIndex((item) => item.active === true) &&
            keyRef.current === 113
          ) {
            dispatch(setLoading(true));
            updateToolsAsBulk(secondLastActiveItem?.id);
          } else if (
            lastActiveItem &&
            dbActive > debouncedInsertedTools.findIndex((item) => item.active === true) &&
            keyRef.current === 114
          ) {
            dispatch(setLoading(true));
            updateToolsAsBulk(lastActiveItem?.id);
          } else if (lastActiveItem && lastActiveItem) {
            dispatch(setLoading(true));
            updateToolsAsBulk(keyRef.current === 113 ? lastActiveItem?.id : secondLastActiveItem?.id);
          } else if (!lastActiveItem) {
            dispatch(setLoading(true));
            updateToolsAsBulk(debouncedInsertedTools.at(-1).id);
          }

          setDebounceActive(false); // Reset debounce active state
        }
      }, DEBOUNCE_DELAY);

      return () => clearTimeout(timeout);
    }
  }, [isDebounceActive, lastKeyPress, debouncedInsertedTools]); // Re-run when debounce or tools change

  const updateToolsAsBulk = (id) => {
    const response = (res) => {
      const list = res.data;
      // eslint-disable-next-line no-plusplus
      for (let idx = 0; idx < list.length; idx++) {
        list[idx].index = list.length - idx;
      }
      dispatch(setToolsInsertedCopy(list));
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.updateToolsAsBulk(response, error, { id });
  };

  const updateStandPipe = (dataItem) => {
    if (auto_tally_stand_status !== '1') return ApiToastHandle(210, 'stand status is OFF', 'info');
    if (!Number(auto_tally_stand_number)) return ApiToastHandle(210, 'please check stands number in settings', 'info');

    const response = (res) => {
      ApiToastHandle(200, 'action was successful', 'success');
      dispatch(getToolsInsertedWithStand('store_stand=stand'));
      dispatch(getToolsInsertedAll('store_stand=all'));
      dispatch(getStoredStands());
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.updateStandPipe(response, error, dataItem);
  };

  const handleF2Key = () => {
    const latestDeActiveItem = _.findLast(toolsInsertedCopy, { active: false });
    if (latestDeActiveItem?.id) {
      updateToolsAsBulk(latestDeActiveItem.id);
    } else {
      ApiToastHandle(400, 'All the strings are active!', 'warning');
    }
  };

  // ? DeActive first active tool
  const handleF3Key = () => {
    const firstActiveItem = _.find(toolsInsertedCopy, { active: true });

    // if (firstActiveItem?.stand_group_number)
    //   return ApiToastHandle(210, 'Item is in stand. Remove Stand with F9.', 'info');
    if (firstActiveItem?.id) {
      updateToolsAsBulk(firstActiveItem.id);
    } else {
      ApiToastHandle(400, 'All the strings are inactive!', 'warning');
    }
  };

  const handlePipes = () => {
    if (checkAutoTallyAndAutoDepth() && modalTxt === 'adding') {
      handleF2Key(autoTally);
    }
    if (checkAutoTallyAndAutoDepth() && modalTxt === 'removing') {
      handleF3Key(autoTally);
    }
  };

  const checkAutoTallyAndAutoDepth = () => {
    if (autoTally) {
      ApiToastHandle(410, 'Auto tally is On!', 'warning');
      return false;
    }
    if (Number(autoDepthstatus.auto_depth) === 1) {
      ApiToastHandle(410, 'Auto Depth is On!', 'warning');
      return false;
    }

    return true;
  };

  const handleCancel = () => {
    setOpenPipeModal(false);
    setModalTxt('Adding');
  };

  const ModalTitle = () => (
    <p className="text-lg">
      <WarningOutlined style={{ color: '#F5D503', fontSize: 23, marginRight: 5 }} />
      {`${modalTxt} Pipe.`}
    </p>
  );

  return (
    <Modal
      open={openPipeModal}
      className="capitalize"
      title={<ModalTitle />}
      centered
      maskClosable={false}
      onOk={handlePipes}
      onCancel={handleCancel}
      afterOpenChange={(open) => {
        if (open && confirmButtonRef.current) {
          confirmButtonRef.current.focus();
        }
      }}
      okButtonProps={{
        style: { backgroundColor: '#3C8CA3' },
        ref: confirmButtonRef,
      }}
      okText="Confirm"
    >
      <p className="text-md capitalize">
        Please confirm <span className="text-md text-primary font-bold">{modalTxt}</span> Pipe.
      </p>
    </Modal>
  );
};

export default PipeController;
