'use-strict';

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import _, { debounce } from 'lodash';
import { AnimatedAlarm, Health, Settings } from 'components/icons';
import { io } from 'socket.io-client';
import SelectOption from 'components/shared/SelectOption';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {
  getSetting,
  getView,
  getViews,
  getDrillerView,
  setLoading,
  setViewId,
  updateSettings,
  getSettingLogo,
  getMudPumps,
  getSelectedDrillerView,
} from 'store/actions/settings';
import { getExportedFiles, setExportingFileStatus } from 'store/actions/report';
import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import UserPopover from 'layout/components/UserPopover';

import { currentUser, getOperationalCode, getProjects, loadingLayer } from 'store/actions/general';
import { Button, Modal, Select, Tooltip } from 'antd';
import {
  chartDelayUpdate,
  tankDelayUpdate,
  getForceRop,
  getFrequentLiveData,
  getFrequentTankData,
  getLiveData,
  getLiveDataAlert,
  getTallyMode,
  setStatusLiveApi,
  updateAlertMessage,
  updateWellProfileTime,
  getDrillingCasingTotalVolume,
} from 'store/actions/liveData';
import Cookies from 'js-cookie';
import useDarkMode from 'hooks/useDarkMode';
import { sendAlertMudSampling } from 'store/actions/audibleAlarm';
import ApiToastHandle from 'containers/ApiToastHandle';
import SettingPopover from 'layout/components/SettingPopover';
import { listOfTimeRes } from 'utils/statics';
import getLogo from 'utils/getLogo';
import { getDrillerLogs } from 'store/actions/drillerMenu';
import LogsSection from 'views/drillerMenuV1/containers/LogsSection';
import { getToolsInserted, getToolsInsertedAll, getToolsInsertedWithStand } from 'store/actions/addTools';
import { getAllMemosOnCurrentSelectedTime } from 'store/actions/memos';
import ActionOperationalCode from 'views/settings/ActionOperationalCode';
import { relevantPath } from 'utils/relevantPath';
import AutoSetWob from './AutoSetWob';
import PipeController from './PipeController';

const { Option } = Select;

const socket = io(process.env.REACT_APP_API_SOCKET);

const NavigationTop = ({ isExporting, shouldAlert }) => {
  const [isDarkMode] = useDarkMode();
  const [searchParams] = useSearchParams();
  const queryParamViewId = searchParams.get('id');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { liveStatus, tallyMode } = useSelector((state) => state.liveData);
  const drillingMode = useSelector((state) => state?.liveData?.data?.drillingMode);
  const [recordMessage, setRecordMessage] = useState('');
  const { chartDelayUPDATE, tankDelayUpdateMeta } = useSelector((state) => state.liveData);
  const generalView = useSelector((state) => state.settings.views);
  const { drillerViews, clientLogo, selectedDrillerView } = useSelector((state) => state.settings);
  const { exportingFiles } = useSelector((state) => state.report);
  const [healthDB, setHealthDB] = useState(false);
  const [isConnectedSocket, setIsConnectedSocket] = useState(false);
  const [plcStatusMessage, setPlcStatusMessage] = useState('');
  const [dataIsNotUpdated, setDataIsNotUpdated] = useState('');
  const [noChartUpdateCounter, setNoChartUpdateCounter] = useState(0);

  const popoverRef = useRef(null);
  const [isPopcnfirmDeletOpen, setIsPopcnfirmDeletOpen] = useState(false);

  const listOfViews = window.location.pathname.includes('create-view')
    ? [...generalView, ...drillerViews]
    : !window.location.pathname.includes('driller-menu')
    ? generalView
    : drillerViews;

  const settingsData = useSelector((state) => state.settings.settings);
  const { projects, user } = useSelector((state) => state.general);
  const { autoRealod } = useSelector((state) => state.audibleAlarm);
  const findDrillerView = _.find(drillerViews, { name: user?.usersetting?.selected_view_driller });
  const project_name = Cookies.get('project_name');
  // check if is in driller menu
  const isUpdatingDrillerMenu = window.location.pathname.includes('driller-menu');
  const [currentProject, setCurrentProject] = useState({
    isEditinPath: false,
    current: {},
  });

  const [openPopover, setOpenPopover] = useState(false);
  const [openReloadModal, setOpenReloadModal] = useState(false);
  const [openMudSampleModal, setOpenMudSampleModal] = useState(false);
  const [operationCode, setOperationCode] = useState(false);
  // Online state
  const [settings, setSettings] = useState(settingsData);
  const [userData, setUserData] = useState(user);
  const logo = getLogo(clientLogo, settingsData, isDarkMode);

  const selectedViewId =
    _.find(listOfViews, { name: isUpdatingDrillerMenu ? userData.selected_view_driller : userData.selected_view })
      ?.id ||
    _.find(listOfViews, {
      name: isUpdatingDrillerMenu ? user?.usersetting?.selected_view_driller : user?.usersetting?.selected_view,
    })?.id;

  useEffect(() => {
    dispatch(chartDelayUpdate(chartDelayUPDATE || Cookies.get('chartDelayUpdate') || 1000));
    dispatch(tankDelayUpdate(tankDelayUpdateMeta || Cookies.get('tankDelayUpdate') || 1000));
    dispatch(getSetting());
    dispatch(getProjects());
    dispatch(getSettingLogo());
    dispatch(currentUser());
    dispatch(getViews());
    dispatch(getOperationalCode());
    dispatch(getTallyMode('tally_mode'));
    dispatch(getDrillerView());
    getOperatedLog();
    if (settingsData) setSettings(settingsData);
    if (userData) {
      setUserData(userData);
      dispatch(loadingLayer(false));
    }
  }, []);

  useEffect(() => {
    if (selectedDrillerView?.id || findDrillerView?.id) {
      dispatch(getSelectedDrillerView(selectedDrillerView.id || findDrillerView.id));
    }
  }, [selectedDrillerView?.id || findDrillerView?.id]);

  useEffect(() => {
    const selectedView =
      _.find(listOfViews, { name: isUpdatingDrillerMenu ? userData.selected_view_driller : userData.selected_view }) ||
      _.find(listOfViews, {
        name: isUpdatingDrillerMenu ? user?.usersetting?.selected_view_driller : user?.usersetting?.selected_view,
      });
    if (selectedView?.id && !isPopcnfirmDeletOpen && !isUpdatingDrillerMenu) {
      dispatch(getView(selectedView.id));
      dispatch(currentUser());
      Cookies.set('selectedView', selectedView.id);
    }
  }, [
    isUpdatingDrillerMenu,
    generalView,
    drillerViews,
    user?.usersetting?.selected_view,
    user?.usersetting?.selected_view_driller,
    user?.usersetting?.selected_view_driller_id,
    userData.selected_view,
    userData.selected_view_driller,
  ]);

  useEffect(() => {
    if (window.location.pathname === '/create-project' && projects) {
      const currentEditingProject = _.find(projects, { name: project_name });
      setCurrentProject({
        isEditinPath: true,
        current: currentEditingProject,
      });
    } else if (window.location.pathname !== '/create-project') {
      setCurrentProject({
        isEditinPath: false,
        current: {},
      });
    }
  }, [project_name, projects]);

  useEffect(() => {
    if (autoRealod) {
      setOpenReloadModal(false);
    }
  }, [autoRealod]);

  const throttledFrequentLiveData = _.throttle((data) => {
    dispatch(getFrequentLiveData(data));
  }, chartDelayUPDATE);

  const throttledFrequentTankLiveData = _.throttle((data) => {
    dispatch(getFrequentTankData(data));
  }, tankDelayUpdateMeta);

  const throttledWellProfileData = _.throttle((data) => {
    dispatch(updateWellProfileTime(data));
  }, 3000);

  const dataRef = useRef(null);

  useEffect(() => {
    const handleIncomingData = (data) => {
      if (data && !location.pathname.includes('/historical') && data.record_off !== 'True' && data.datatime) {
        throttledFrequentLiveData(data);
        throttledFrequentTankLiveData(data);
        throttledWellProfileData(data);
        setRecordMessage('');
      }
    };

    socket.on('connect', () => {
      console.log('connect');
      setTimeout(() => {
        setIsConnectedSocket(true);
      }, 3000);
    });

    socket.on('disconnect', () => {
      console.log('disconnect');
      setIsConnectedSocket(false);
      setTimeout(() => {
        socket.connect();
      }, 3000);
    });

    socket.on('real_data', (data) => {
      dataRef.current = data;

      // If status is 'NO_UPDATE'
      if (data?.status === 'NO_UPDATE') {
        setNoChartUpdateCounter((prev) => prev + 1);
      } else {
        // If the status changes from 'NO_UPDATE', reset the counter and clear the message
        setNoChartUpdateCounter(0);
        setDataIsNotUpdated('');
      }

      // Handle record message if present
      if (data?.record_message) setRecordMessage(data?.record_message);

      // Dispatch live data actions if relevant path is valid and data is present
      if (relevantPath(location)) {
        if (data && data.datatime) {
          dispatch(getLiveData(data));
          handleIncomingData(data);
        }
      }

      // Dispatch live data alert if the path is relevant and record is not off
      if (relevantPath(location) && data.record_off !== 'True') {
        dispatch(getLiveDataAlert(data));
      }

      // Handle error or data health check (optional)
      if (data?.error) {
        setRecordMessage('');
      }
    });

    // const intervalId = setInterval(() => {
    //   if (
    //     dataRef.current &&
    //     !location.pathname.includes('/historical') &&
    //     dataRef.current.record_off !== 'True' &&
    //     dataRef?.current?.datatime
    //   ) {
    //     dispatch(getFrequentLiveData(dataRef.current));

    //     setRecordMessage('');
    //   }
    // }, chartDelayUPDATE);

    // const intervalIdTankUpdate = setInterval(() => {
    //   if (
    //     dataRef.current &&
    //     !location.pathname.includes('/historical') &&
    //     dataRef.current.record_off !== 'True' &&
    //     dataRef?.current?.datatime
    //   ) {
    //     dispatch(getFrequentTankData(dataRef.current));

    //     setRecordMessage('');
    //   }
    // }, tankDelayUpdateMeta);

    // const intervalWellProfile = setInterval(() => {
    //   if (dataRef.current && location.pathname.includes('/historical') && dataRef.current.record_off !== 'True') {
    //     dispatch(updateWellProfileTime(dataRef.current));
    //     setRecordMessage('');
    //   }
    // }, 300000);

    // Cleanup function to avoid memory leaks
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('real_data');

      // clearInterval(intervalId);
      // clearInterval(intervalIdTankUpdate);
      // clearInterval(intervalWellProfile);

      // Cancel any pending throttled calls
      throttledFrequentLiveData.cancel();
      throttledFrequentTankLiveData.cancel();
      throttledWellProfileData.cancel();
    };
  }, [socket, dispatch, location, relevantPath, location.pathname, chartDelayUPDATE, tankDelayUpdateMeta]);

  useEffect(() => {
    let socket;
    let reconnectTimeout;

    const checkPlcStatus = (event) => {
      switch (event) {
        // ! When data is ok and coming
        case 'MQTT_MODE_2':
          dispatch(setStatusLiveApi(true));
          break;
        // ! When data is empty but coming
        case 'MQTT_MODE_1':
          dispatch(setStatusLiveApi(true));
          setPlcStatusMessage('Empty data.');
          break;
        // ! ٌhen data is not coming, PLC has issue or off
        case 'MQTT_MODE_0':
          dispatch(setStatusLiveApi(false));
          setPlcStatusMessage('Plc / data issue.');
          break;

        default:
          break;
      }
    };

    const checkDbHealth = (event) => {
      switch (event) {
        case 'DATABASE_MODE_ON':
          setHealthDB(true);
          break;
        case 'DATABASE_MODE_OFF':
          setHealthDB(false);
          break;
        default:
          break;
      }
    };

    const debouncedGetToolsInsertedAll = debounce(() => {
      dispatch(getToolsInsertedAll('store_stand=all', true));
    }, 1000);

    const handleExportingFilesStatusEvent = (event) => {
      try {
        // Extract the JSON payload within the "FILE_STATUS" event
        const payload = event.split('FILE_STATUS: ')[1]?.trim();

        if (payload) {
          // Parse the payload as JSON
          const parsedPayload = JSON.parse(payload);

          // Extract the fileName and percentage
          const [fileName, percentage] = Object.entries(parsedPayload)[0];

          const index = _.findIndex(exportingFiles, { fileName });

          if (index !== -1) {
            // Replace the object at the found index with the updated object
            exportingFiles[index] = { ...exportingFiles[index], percentage };
          } else {
            exportingFiles.push({ fileName, percentage });
            dispatch(getExportedFiles());
          }

          const list = exportingFiles.filter((item) => item.percentage !== 100);

          dispatch(setExportingFileStatus(list));
        }
      } catch (error) {
        console.error('Error processing FILE_STATUS event:', error);
      }
    };

    const handleSocketMessage = (data) => {
      try {
        const parsedData = JSON.parse(data);
        const event = parsedData?.event;

        if (!event) return;

        // Handle FILE_STATUS separately
        if (event.includes('FILE_STATUS')) {
          handleExportingFilesStatusEvent(event);
        }

        // Event-specific actions
        switch (event) {
          case 'MEMO':
            dispatch(getAllMemosOnCurrentSelectedTime());
            break;
          case 'RELOAD':
            setOpenReloadModal(true);
            break;
          case 'FORCE_ROP':
            dispatch(getForceRop());
            break;
          case 'CASING:T_VOLUME':
            dispatch(getDrillingCasingTotalVolume());
            break;
          case 'STRING':
            dispatch(getToolsInserted());
            dispatch(getToolsInsertedWithStand('store_stand=stand'));
            debouncedGetToolsInsertedAll();
            dispatch(getDrillerLogs());
            break;
          case 'UPDATE_PUMPS':
            dispatch(getMudPumps());
            break;
          case 'OPERATION':
            getOperatedLog();
            break;
          case 'EXPORT_FILE':
            dispatch(getExportedFiles());
            break;
          default:
            handleDefaultEvent(event);
            break;
        }
      } catch (error) {
        console.error('Error processing socket message:', error);
      }
    };

    const handleDefaultEvent = (event) => {
      if (event.includes('tally_mode')) {
        dispatch(getTallyMode('tally_mode'));
      }

      if (event.includes('ALARM_DEPTH') && user?.role?.includes('mud')) {
        dispatch(sendAlertMudSampling(true));
        setOpenMudSampleModal(true);
      }

      if (event.includes('UPDATE_ALERT')) {
        dispatch(updateAlertMessage(event));
      }

      checkPlcStatus(event);
      checkDbHealth(event);
    };

    const handleSocketOpen = () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    const handleSocketClose = () => {
      console.log('disconnected from sockets');
      dispatch(setStatusLiveApi(false));
      setHealthDB(false);
      setPlcStatusMessage('');

      // Attempt to reconnect after a delay
      reconnectTimeout = setTimeout(() => {
        console.log('Attempting to reconnect...');
        connectWebSocket();
      }, 3000); // 3 seconds delay
    };

    const handleSocketError = (error) => {
      console.log('WebSocket error:', error);
      dispatch(setStatusLiveApi(false));
      setHealthDB(false);
      setPlcStatusMessage('Connection closed!');
    };

    const connectWebSocket = () => {
      socket = new WebSocket(process.env.REACT_APP_API_SOCKET_RELOAD);
      socket.addEventListener('open', handleSocketOpen);
      socket.addEventListener('close', handleSocketClose);
      socket.addEventListener('message', (event) => handleSocketMessage(event.data));
      socket.addEventListener('error', handleSocketError);
    };

    const disconnectWebSocket = () => {
      if (socket) {
        socket.removeEventListener('open', handleSocketOpen);
        socket.removeEventListener('close', handleSocketClose);
        socket.removeEventListener('message', (event) => handleSocketMessage(event.data));
        socket.removeEventListener('error', handleSocketError);
        socket.close();
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    connectWebSocket();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && socket?.readyState !== WebSocket.OPEN) {
        console.log('Reconnecting Socket...');
        disconnectWebSocket();
        connectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      disconnectWebSocket();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (Number(noChartUpdateCounter) >= 10) {
      setDataIsNotUpdated('no new data coming');
    }
  }, [noChartUpdateCounter]);

  const [editView, setEditView] = useState({
    delete: false,
    createNew: false,
  });

  const handleChange = async (name) => {
    const selectedView = listOfViews.find((x) => x.name === name);
    const isAddingDrillerView = drillerViews.find((x) => x.name === name);

    if (selectedView) {
      dispatch(setLoading(true));
      const idView = selectedView.id;

      const viewIdKey = selectedView?.is_driller_menu ? 'selected_view_driller_id' : 'selected_view_id';
      const viewNameKey = selectedView?.is_driller_menu ? 'selected_view_driller' : 'selected_view';

      // Single operation to update settings and user data
      const updateState = (prevState) => ({
        ...prevState,
        [viewIdKey]: idView,
        [viewNameKey]: name,
      });

      setSettings(updateState);
      setUserData(updateState);
      // Dispatch with dynamic keys
      dispatch(updateSettings({ [viewIdKey]: idView, [viewNameKey]: name }));
      dispatch(setViewId(idView));

      Cookies.remove('selected_traces');

      if (selectedView?.is_driller_menu) {
        dispatch(getSelectedDrillerView(selectedView.id));
        return;
      }
      dispatch(getView(selectedView.id));
    }
  };

  const handleChangeChartRes = (_, data) => {
    dispatch(chartDelayUpdate(data.value));
    Cookies.set('chartDelayUpdate', data.value);
  };

  const handleChangeProject = (name) => {
    const project = _.find(projects, { name });

    setSettings((prevState) => ({
      ...prevState,
      selectedProject: {
        name: project.name,
        id: project.id,
      },
    }));
    dispatch(updateSettings({ selected_project: project.name, selected_project_id: project.id }));
  };

  const handleEditViews = (name, value) => {
    if (name === 'createNew' && window.location.pathname === '/driller-menu') {
      setEditView({
        delete: false,
        createNew: false,
      });
      window.location.replace('/create-view?is_driller_menu=true');
    } else if (name === 'createNew') {
      setEditView({
        delete: false,
        createNew: false,
      });
      window.location.replace('/create-view');
    } else {
      setEditView((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRemoveView = (id, item) => {
    const defaultView = listOfViews.find((item) => item.name.toLowerCase() === 'default');

    const defaultDrillerView = listOfViews.find(
      (item) => item.name.toLowerCase() === 'default' && item.is_driller_menu === true
    );

    const isDrillerView = item.is_driller_menu;

    const response = (res) => {
      ApiToastHandle(200, 'view removed successfully', 'success');
      dispatch(currentUser());
      dispatch(getViews());
      dispatch(getDrillerView());

      if (selectedViewId === item.id && !item.is_driller_menu) {
        dispatch(updateSettings({ selected_view_id: defaultView.id, selected_view: defaultView.name }));
        handleEditViews('delete', false);
      }

      if (selectedViewId === item.id && isDrillerView) {
        dispatch(
          updateSettings({
            selected_view_driller: defaultDrillerView?.name || '',
            selected_view_driller_id: defaultDrillerView?.id || '',
          })
        );
        dispatch(getSelectedDrillerView(defaultDrillerView?.id));
        handleEditViews('delete', false);
      }

      // eslint-disable-next-line eqeqeq
      if (location.pathname.includes('create-view') && queryParamViewId == item.id) {
        window.location.reload();
      }
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    if (isDrillerView) {
      httpService.removeDrillerView(response, error, id);
      return;
    }

    httpService.removeView(response, error, id);
  };

  const handleChangeOpenPopover = (isOpen) => {
    setOpenPopover(isOpen);
    handleEditViews('delete', false);
  };

  const handleEditViewItem = (id, is_driller_menu) => {
    const isCreateViewRoute = location.pathname.includes('create-view');

    const toEditView = _.find(listOfViews, { id, is_driller_menu });
    const isDrillerView = toEditView?.is_driller_menu;

    if (isCreateViewRoute) {
      window.location.replace(`/create-view?id=${id}&is_driller_menu=${isDrillerView}`);
    } else {
      navigate(`/create-view?id=${id}&is_driller_menu=${isDrillerView}`);
    }
  };

  const getOperatedLog = () => {
    const response = (res) => {
      setOperationCode(res.data);
    };

    const error = () => {};

    httpService.getOperatedLog(response, error, 'last/log');
  };

  const handleCloseModalMudSampleModal = () => {
    setOpenMudSampleModal(false);
    dispatch(sendAlertMudSampling(false));
  };

  const handleMudSampleModal = () => {
    setOpenMudSampleModal(false);
    dispatch(sendAlertMudSampling(false));
  };

  const SampleModalFooter = () => (
    <div className="w-full flex justify-end items-center space-x-2">
      <Button onClick={handleMudSampleModal}>ok</Button>
    </div>
  );

  // * VIEW FUNCTIONS
  const handleEditView = (name) => {
    if (openPopover && editView.delete && !editView.createNew) {
      handleEditViews(name, false);
    } else if (openPopover && !editView.delete && !editView.createNew) {
      handleEditViews(name, true);
    }
  };

  const removeView = (id, item) => {
    handleRemoveView(id, item);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!isPopcnfirmDeletOpen && popoverRef.current && !popoverRef.current?.contains(event.target)) {
        setOpenPopover(false);
      }
    };

    document.addEventListener('mousedown', handleDocumentClick);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [isPopcnfirmDeletOpen]);

  const [counter, setCounter] = useState(null);

  useEffect(() => {
    // Check if there's a saved counter in cookies
    const savedCounter = Cookies.get('reload-counter');
    if (savedCounter) {
      setCounter(parseInt(savedCounter, 10));
    }

    // Set up the socket listener
    if (openReloadModal) {
      // Assume socketEvent is a boolean indicating the socket event occurrence
      setCounter(10); // Start the counter from 10
      Cookies.set('reload-counter', 10); // Save the initial counter value to cookies
    }
  }, [openReloadModal]);

  useEffect(() => {
    if (counter === null) return; // If counter is not initialized, do nothing

    if (counter === 0) {
      // If counter reaches 0, reload the page
      Cookies.remove('reload-counter'); // Remove the counter from cookies
      window.location.reload(); // Reload the page
    } else {
      // Set up a countdown
      const timer = setTimeout(() => {
        setCounter((prevCounter) => {
          const newCounter = prevCounter - 1;
          Cookies.set('reload-counter', newCounter); // Update the counter in cookies
          return newCounter;
        });
      }, 1000);

      // Clean up the timer on component unmount or counter change
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const [chainingCode, setChainingCode] = useState(false);
  const onSubmitOperationalCode = () => setChainingCode(false);

  const refActionCode = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click is outside the componentRef and NOT on interactive elements like dropdowns or modals
    if (
      refActionCode.current &&
      !refActionCode.current.contains(event.target) &&
      !event.target.closest('.ant-select-dropdown') &&
      !event.target.closest('.ant-picker-dropdown') &&
      !event.target.closest('.ant-modal')
    ) {
      setChainingCode(false); // Only close when clicking outside relevant elements
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div id="appNavigation">
      <div
        className="
        z-50
        capitalize
        w-full
        flex-1
        flex
        flex-col
        space-y-0
        xl:space-y-0
        xl:flex-row
        items-center
        justify-between
        font-medium
        text-xs
        lg:text-sm
        border
        border-gray-200
        rounded-md
        px-3
        py-3
        lg:py-4
        bg-white
        dark:bg-dark
        dark:text-white
        dark:border-lightDark
      "
      >
        {/* right */}
        <div className="w-full xl:w-fit pb-2 xl:border-none xl:pb-0 z-30">
          <ul className="flex items-center lg:items-center justify-between md:justify-start gap-x-4">
            <li>
              {logo ? (
                <div className="w-fit max-w-28 min-w-12 flex items-start gap-x-1">
                  <a href="/home">
                    <img
                      // src={`data:image/png;base64,${process.env.REACT_APP_API_MEDIA}${logo}`
                      src={`data:image/png;base64,${logo}`}
                      className="h-[33px] lg:h-[43px] w-full p-0 m-0"
                      alt="main logo saxon"
                    />
                  </a>
                </div>
              ) : (
                <a href="/home">
                  <span className="text-2xl font-semibold p-0 m-0 mb-2 capitalize">
                    {settings.company_name || 'saxon'}
                  </span>
                </a>
              )}
            </li>
            {!isExporting && (
              <li className="flex items-start lg:items-start text-base gap-x-4 lg:flex-col gap-y-2 space-y-0 xl:flex-row relative">
                {!location.pathname.includes('/historical') ? (
                  <div className="flex flex-col gap-y-1">
                    <div className="flex items-center text-sm">
                      <div className="pr-2">project:</div>
                      {settingsData?.live_project}
                    </div>
                    <div className="flex items-center text-sm">
                      <div className="pr-2">rig:</div>
                      {_.find(projects, { name: settingsData.selected_project })?.rig_name}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-y-1">
                    <SelectOption
                      size="middle"
                      className=" min-select"
                      disabled={currentProject?.isEditinPath}
                      value={
                        currentProject?.isEditinPath ? currentProject?.current?.name : settingsData?.selected_project
                      }
                      handleChange={handleChangeProject}
                      options={projects}
                    />
                    <div className="flex items-center text-sm">
                      <div className="pr-2">rig:</div>
                      {_.find(projects, { name: settingsData.selected_project })?.rig_name}
                    </div>
                  </div>
                )}
                {!chainingCode ? (
                  <Tooltip
                    disabled
                    className="flex items-center relative"
                    title={operationCode?.operation_code?.description}
                  >
                    <button
                      type="button"
                      onClick={() => setChainingCode(!chainingCode)}
                      className={`${drillingMode === '1' ? 'text-success' : 'text-error'} capitalize
                    `}
                    >
                      {operationCode?.code} <span>{operationCode?.operation_code?.name?.toLowerCase()}</span>
                    </button>
                    {!healthDB && <span className="pl-4">Remote Mode</span>}
                  </Tooltip>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={() => setChainingCode(!chainingCode)}
                      className={`${drillingMode === '1' ? 'text-success' : 'text-error'} capitalize
                    `}
                    >
                      {operationCode?.code} <span>{operationCode?.operation_code?.name?.toLowerCase()}</span>
                    </button>
                    <div
                      ref={refActionCode}
                      className="max-w-[350px] w-[350px] absolute top-10 dark:bg-dark rounded-md border bg-white -left-40 md:left-20"
                    >
                      <ActionOperationalCode
                        onclose={() => setChainingCode(false)}
                        isAddingFromHeader
                        onSubmitOperationalCode={onSubmitOperationalCode}
                      />
                    </div>
                  </div>
                )}
              </li>
            )}
            {shouldAlert && (
              <div>
                <Button
                  danger
                  type="link"
                  // onClick={handleClickAlarm}
                  className="flex justify-start items-center gap-x-1 capitalize font-bold rounded-full p-0"
                >
                  <AnimatedAlarm />
                  <div>
                    <div>you have an alert</div>
                  </div>
                </Button>
              </div>
            )}
          </ul>
        </div>
        {/* center */}
        <div className="">
          {openReloadModal && (
            <div className=" capitalize font-semibold">
              The system has been updated and your browser will reload in{' '}
              <span className=" font-bold text-error">{counter}</span> seconds
            </div>
          )}
        </div>
        {/* left */}
        <div className="self-end w-full lg:w-fit" style={{ zIndex: 29 }}>
          <ul className="flex justify-between xl:justify-end items-center gap-4 flex-wrap">
            <li>
              {/* Auto Reference WOB  */}
              <AutoSetWob />

              {/* ! PIPS LOGS */}
              <PipeController />
              {tallyMode !== 'auto_depth' && <LogsSection />}
            </li>
            <li className="flex flex-col space-y-1">
              {(window?.location?.pathname.includes('/home') ||
                window?.location?.pathname.includes('driller-menu')) && (
                <div className="flex flex-col items-center text-sm min-w-[100px]">
                  <span>Chart updates</span>
                  <Select
                    value={_.find(listOfTimeRes, { value: Number(chartDelayUPDATE) })?.name}
                    onChange={(_, data) => handleChangeChartRes(_, data)}
                    className="hover:shadow-none hover:border-gray-200 min-select min-w-[100px]"
                  >
                    {listOfTimeRes.map((item) => (
                      <Option key={item.id} value={item.value} label={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </li>
            <li>
              {healthDB ? (
                <div className=" flex flex-col justify-between -space-y-1 text-sm">
                  {recordMessage.length > 1 && (
                    <div className={`text-error  text-sm font-semibold flash-animation-bang`}>{recordMessage}</div>
                  )}

                  <div className="flex items-center space-x-1 cursor-default">
                    <span>PLC status:</span>
                    {isConnectedSocket ? (
                      <span
                        className={`${
                          liveStatus ? 'text-success' : 'text-error flash-animation-bang'
                        }  text-xs font-semibold ml-2`}
                      >
                        {liveStatus ? 'Online' : 'Offline'}
                      </span>
                    ) : (
                      <span>connecting</span>
                    )}
                  </div>

                  {plcStatusMessage && (
                    <span className={`text-[11px] ${liveStatus ? 'text-warning' : 'text-error'}`}>
                      {plcStatusMessage}
                    </span>
                  )}

                  <div className="flex items-center space-x-1">
                    <span>DB Health:</span>
                    {isConnectedSocket ? (
                      <span className={`${!healthDB && 'flash-animation-bang'}`}>
                        <Health color={`${healthDB ? '#6ABE39' : '#E84749'}`} />
                      </span>
                    ) : (
                      <span>connecting</span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="-space-y-1 text-sm">
                  {dataIsNotUpdated}
                  {/* {noChartUpdateCounter} */}
                </div>
              )}
            </li>
            <li className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 relative ">
                <button
                  className="flex items-center space-x-1 text-sm"
                  type="button"
                  onClick={() => handleChangeOpenPopover(!openPopover)}
                >
                  <Settings color={isDarkMode ? '#fff' : undefined} />
                  <span>Setup views</span>
                </button>
                {openPopover && (
                  <SettingPopover
                    onSelectView={handleChange}
                    popoverRef={popoverRef}
                    listOfViews={listOfViews}
                    selectedViewId={selectedViewId}
                    settings={settings}
                    editView={editView}
                    isDarkMode={isDarkMode}
                    handleEditViewItem={handleEditViewItem}
                    setIsPopcnfirmDeletOpen={setIsPopcnfirmDeletOpen}
                    removeView={removeView}
                    handleEditView={handleEditView}
                    role={user.role}
                  />
                )}
              </div>
              <div>
                <UserPopover isDarkMode={isDarkMode} />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Modal
        footer={<SampleModalFooter />}
        title="Depth Sampling Alarm"
        open={openMudSampleModal}
        onOk={handleMudSampleModal}
        onCancel={handleCloseModalMudSampleModal}
      >
        <p>Depth has changed 5 meter, please take a sample</p>
      </Modal>
    </div>
  );
};

export default NavigationTop;
