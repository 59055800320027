import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import * as types from '../types';

export const toggleReportLoading = () => (dispatch) => {
  dispatch({
    type: types.TOGGLE_REPORT_LOADING,
  });
};

export const getExportedFiles = () => (dispatch) => {
  const response = (res) => {
    const list = res?.data || [];
    const listWithKey = list.map((item, idx) => ({ ...item, key: idx }));

    dispatch({
      type: types.GET_EXPORTED_FILES,
      payload: listWithKey,
    });
  };

  const error = (error) => {
    ApiErrorHandler(error);
  };

  httpService.getExportedFiles(response, error);
};

export const setExportingFileStatus = (payload) => (dispatch) => {
  dispatch({
    type: types.SET_EXPORTING_FILE_STATUS,
    payload,
  });
};
