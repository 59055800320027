import { Suspense, useEffect, useRef, useState } from 'react';
import { EmailIcon, Phone, Location } from 'components/icons';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import withAudibleAlert from 'utils/hoc/withAudibleAlert';
import LazyLoadingIndicator from 'components/shared/LazyLoadingIndicator';
import NavigationTop from './includes/NavigationTop';
import DrawerMenu from './includes/DrawerMenu';

function MainLayout({ shouldAlert }) {
  const settings = useSelector((state) => state.settings.settings);
  const path = window.location.pathname;
  const generalPath = path.includes('/about');
  const drillerMenu = path.includes('/driller-menu');
  const mainPath = path.includes('/home');
  const [scrollPosition, setScrollPosition] = useState(0);

  const navigationRef = useRef(null);
  const [navHeight, setNavHeight] = useState(0);

  const updateNavHeight = () => {
    if (navigationRef.current) {
      setNavHeight(navigationRef.current.offsetHeight + 20);
    }
  };

  useEffect(() => {
    // Initial height calculation
    updateNavHeight();

    const handleScroll = () => {
      setScrollPosition(window.scrollY); // Update scroll position
    };

    // Update height on window resize (width or height change)
    const handleResize = () => {
      updateNavHeight();
    };
    // Add event listener on mount
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <div
      className={` bg-gray-100 text-primaryDark font-normal h-screen ${drillerMenu ? ' min-w-fit' : 'w-screen '} ${
        mainPath ? 'overflow-hidden' : 'overflow-auto'
      } flex flex-col gap-y-1 ${generalPath ? '' : 'p-2'} dark:bg-darker dark:text-white`}
    >
      <div ref={navigationRef} className={`navigationRef ${!generalPath ? '' : 'px-2 pt-2'}`}>
        <NavigationTop path={generalPath} settingsApi={settings} shouldAlert={shouldAlert} />
      </div>
      <Suspense fallback={<LazyLoadingIndicator />}>
        <div className="flex items-start gap-x-2 h-full w-full max-w-full">
          <div
            className={`sticky top-2 max-h-screen z-50 transition-all duration-500 ease-in-out ${
              scrollPosition > 5 ? 'h-[calc(100vh-15px)]' : 'h-[calc(100vh-125px)]'
            }`}
          >
            <DrawerMenu navHeight={navHeight} />
          </div>
          <div className={`flex-1 w-full ${!drillerMenu ? 'h-full overflow-x-auto overflow-y-hidden' : ''}`}>
            <Outlet />
          </div>
        </div>
      </Suspense>
      {generalPath && (
        <footer className="w-full bg-white dark:bg-lightDark fixed bottom-0 py-6">
          <div className="w-full max-w-xl flex flex-col gap-y-2 justify-start items-start mx-auto">
            <div className="text-lg md:text-2xl mb-2">Contact us</div>
            <div className="flex items-center space-x-1 text-primary dark:text-white font-semibold">
              <Location color="#3C8CA3" />
              <p>Business Bay, Dubai, United Arab Emirates. </p>
            </div>
            <div className="flex items-center space-x-1 text-primary dark:text-white font-semibold">
              <EmailIcon color="#3C8CA3" />
              <a href="mailto:sales@saxondrilling.com">sales@saxondrilling.com</a>
            </div>
            <div className="flex items-center space-x-1 text-primary dark:text-white font-semibold">
              <Phone color="#3C8CA3" />
              <div>
                <a href="tel:+97145876552" className=" ml-1">
                  Tel.: +971 4 5876552
                </a>
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
}

export default withAudibleAlert(MainLayout);
